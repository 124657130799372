<template>
	<div>
		<CRow>
			<CCol class="text-right">
				<CDropdown
					:disabled="isExporting"
					placement="bottom-end"
					data-test-id="export"
					add-menu-classes="export-dropdown-menu"
					class="btn-export mb-3 pr-0"
					color="tertiary"
				>
					<CDropdownItem @click="exportOrder('order')">
						Order Ustore
					</CDropdownItem>
					<CDropdownItem @click="exportOrder('order_product')">
						Order by Product
					</CDropdownItem>
					<CDropdownItem @click="exportOrder('order_offline_branch')">
						Order by Branch
					</CDropdownItem>
					<template #toggler-content>
						{{ isExporting ? "Exporting" : "Export" }}
					</template>
				</CDropdown>
			</CCol>
		</CRow>
		<!-- Filter -->
		<CRow>
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						placeholder="Search by order ID, staff ID, customer name and phone number"
					>
						<template #prepend-content>
							<CIcon name="cil-magnifying-glass" />
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					class="ml-3"
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="filter-box rounded-sm pt-3 px-3 mb-3"
					>
						<CRow>
							<CCol md="3">
								<BaseDropDown
									v-model="dropdown.order"
									:options="ORDER_STATUS_OPTIONS"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Order status"
									class="select-custom"
									@input="handleStatusOrderChange"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="dropdown.payment"
									:options="PAYMENT_STATUS_OPTIONS"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Payment status"
									class="select-custom"
									@input="handleStatusPaymentChange"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="dropdown.shipmentStatus"
									:options="SHIPMENT_STATUS_OPTIONS"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Shipment status"
									class="select-custom"
									@input="handleStatusShippingChange"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="paymentMethod"
									:options="paymentMethodOptions"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Payment method"
									class="select-custom"
									@input="handlePaymentMethodChange"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="getSelectedValue(queryParams.shipping_method, ShippingMethodStatusOptions)"
									:options="ShippingMethodStatusOptions"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Shipping method"
									class="select-custom"
									@input="handleShippingMethodChange"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="dropdown.customerGroup"
									:options="customerGroupOptions"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Customer group"
									class="select-custom"
									@input="handleCustomerGroupChange"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="dropdown.orderType"
									:options="ORDER_TYPE_OPTIONS"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Order type"
									class="select-custom"
									@input="handleOrderTypeChange"
								/>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="dateRange"
									label="Order period"
									placeholder="All periods"
									mode="range"
									@input="handleFilterDateChange"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="dropdown.channel"
									:options="ORDER_CHANNEL_OPTIONS"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Channel"
									class="select-custom"
									@input="handleChannelChange"
								/>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="paymentExpireDateRange"
									label="Payment expire period"
									placeholder="All periods"
									mode="range"
									@input="handleFilterPaymentExpireDateChange"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="dropdown.staffBranchId"
									:options="branchIdOptions"
									:searchable="true"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="Branch ID"
									class="select-custom"
									@input="handleBranchIdChange"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="dropdown.ecRedeemedStatus"
									:options="ORDER_EC_REDEEMED_OPTIONS"
									:searchable="false"
									:allow-empty="false"
									label="name"
									track-by="value"
									label-drop-down="EC Redeemed status"
									class="select-custom"
									@input="handleEcRedeemedChange"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>
		<CRow class="mb-4">
			<CCol>
				<BaseSearchFormFooter
					:count="meta.total"
					append-text="order(s) found"
					@onReset="resetFilter"
				/>
			</CCol>
		</CRow>
		<!-- End Filter -->

		<!-- Table -->
		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: meta.lastPage,
						activePage: meta.currentPage
					}"
					:fields="ORDER_TABLE_FIELDS"
					:items="dataTable"
					clickable-rows
					link-to="OrderDetail"
					class="table-custom table-custom-link"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t("global.searchNotFound", { field: "orders" }) }}
							</p>
						</div>
					</template>
					<template #status="{ item = '' }">
						<CBadge class="badge-order-status" :color="item.color">
							{{ item.title }}
						</CBadge>
					</template>
					<template #payment="{ item = '' }">
						<CBadge class="badge-order-status" :color="item.color">
							{{ item.title }}
						</CBadge>
					</template>
					<template #shipment="{ item = '' }">
						<CBadge class="badge-order-status" :color="item.color">
							{{ item.title }}
						</CBadge>
					</template>
					<template #ec_redeemed_status="{ item = '' }">
						<CBadge class="badge-order-status" :color="item.color">
							{{ item.title }}
						</CBadge>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
		<!-- End Table -->
		<BaseModalConfirm
			ref="modal-export-order"
			:is-submitting="isExporting"
			:close-after-confirm="false"
			title="Export report"
			description="The report will be sent to your email after combinded all the files. It might be taking for 5 - 30 mins, please be patient."
			primary-button-text="Send"
			primary-button-loading-text="Sending"
			@onConfirm="handleExportOrder"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import {
	ORDER_TABLE_FIELDS,
	ORDER_COLOR,
	ORDER_TITLE,
	ORDER_STATUS_OPTIONS,
	ORDER_TYPE_OPTIONS,
	ORDER_CHANNEL_OPTIONS,
	ORDER_ITEM_TYPE_TITLE,
	ORDER_EC_REDEEMED_OPTIONS,
	ORDER_EC_REDEEMED_COLOR,
	ORDER_EC_REDEEMED_TITLE,
} from '../enums/orders';
import { randomSearchString, cleanObject, cloneDeep, hasSearchFilter, convertDateRangeToUTC } from '../assets/js/helpers';
import { transformedShippingMethodDropdownOption } from '../assets/js/transform/shippings';
import { transformedWarehouseDropdownOption } from '../assets/js/transform/warehouses';
import { PAYMENT_STATUS_OPTIONS } from '../enums/payments';
import { SHIPMENT_STATUS_OPTIONS } from '../enums/shipments';
import { postOrderReportExport } from '../services/api/orders.api';

export default {
	name: 'Orders',

	components: {
		BaseModalConfirm,
	},
	data() {
		return {
			ORDER_TABLE_FIELDS,
			ORDER_STATUS_OPTIONS,
			PAYMENT_STATUS_OPTIONS,
			SHIPMENT_STATUS_OPTIONS,
			ORDER_TYPE_OPTIONS,
			ORDER_CHANNEL_OPTIONS,
			ORDER_ITEM_TYPE_TITLE,
			ORDER_EC_REDEEMED_OPTIONS,
			// query params from url
			queryParams: {
				q: this.$route.query.q || null,
				customer_id: this.$route.query.customer_id || null,
				status: this.$route.query.status,
				payment_status: this.$route.query.payment_status,
				payment_method_group: this.$route.query.payment_method_group,
				shipping_status: this.$route.query.shipping_status,
				shipping_method: Number(this.$route.query.shipping_method) || null,
				customer_group: this.$route.query.customer_group,
				store: this.$route.query.store ? Number(this.$route.query.store) : null,
				type: this.$route.query.type,
				created_from: this.$route.query.created_from || null,
				created_to: this.$route.query.created_to || null,
				payment_expire_after: this.$route.query.payment_expire_after || null,
				payment_expire_before: this.$route.query.payment_expire_before || null,
				page: this.$route.query.page ? Number(this.$route.query.page) : null,
				staff_branch_id: this.$route.query.staff_branch_id ?? null,
				ec_redeemed_status: this.$route.query.ec_redeemed_status,
				r: randomSearchString(),
			},
			// dropdown status values, set default value following from params
			dropdown: {
				order: this.getSelectedValue(this.$route.query.status, ORDER_STATUS_OPTIONS),
				payment: this.getSelectedValue(this.$route.query.payment_status, PAYMENT_STATUS_OPTIONS),
				shipmentStatus: this.getSelectedValue(this.$route.query.shipping_status, SHIPMENT_STATUS_OPTIONS),
				customerGroup: this.getSelectedValue(this.$route.query.customer_group, this.customerGroupOptions),
				channel: this.getSelectedValue(this.$route.query.store, ORDER_CHANNEL_OPTIONS),
				orderType: this.getSelectedValue(this.$route.query.type, ORDER_TYPE_OPTIONS),
				staffBranchId: this.getSelectedValue(this.$route.query.staff_branch_id, this.branchIdOptions),
				ecRedeemedStatus: this.getSelectedValue(this.$route.query.ec_redeemed_status, ORDER_EC_REDEEMED_OPTIONS),
			},
			isExporting: false,
			isShowFilter: false,
			paymentMethod: null,
			exportType: null,
		};
	},

	computed: {
		...mapState('orders', {
			list: 'list',
		}),
		...mapState('customerGroups', {
			customerGroupList: 'list',
		}),

		...mapGetters({
			paymentMethodGroupList: 'payments/paymentMethodGroupList',
			shippingMethodList: 'shippings/shippingMethodList',
			warehouseOptions: 'warehouses/warehouseOptions',
		}),

		isLoading() {
			return this.list.isLoading;
		},
		meta() {
			return this.list.meta;
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		dateRange() {
			if (!this.queryParams.created_from || !this.queryParams.created_to) {
				return null;
			}

			return {
				start: new Date(this.queryParams.created_from),
				end: new Date(this.queryParams.created_to),
			};
		},
		paymentExpireDateRange() {
			if (!this.queryParams.payment_expire_after || !this.queryParams.payment_expire_before) {
				return null;
			}

			return {
				start: new Date(this.queryParams.payment_expire_after),
				end: new Date(this.queryParams.payment_expire_before),
			};
		},
		// mapping for data table
		dataTable() {
			return this.list.data.map((item) => ({
				id: item.id,
				order_code: item.orderCode,
				type: ORDER_ITEM_TYPE_TITLE[item.orderType],
				status: {
					title: ORDER_TITLE.ORDER[item.orderStatus],
					color: ORDER_COLOR.ORDER[item.orderStatus],
				},
				created: item.createdAt,
				payment_expired: item.paymentExpiredAt,
				customer_name: `${item.customer.firstName} ${item.customer.lastName}`,
				group_name: item.customer.customerGroup.name,
				phone_number: item.customer.phoneNumber,
				email: item.customer.email,
				payment_method_group: item.payment.paymentMethodName,
				shipping_method: item.shipping.shippingMethodName,
				total: item.total,
				payment: {
					title: ORDER_TITLE.PAYMENT[item.payment.status],
					color: ORDER_COLOR.PAYMENT[item.payment.status],
				},
				shipment: {
					title: ORDER_TITLE.SHIPPING[item.shipping.status],
					color: ORDER_COLOR.SHIPPING[item.shipping.status],
				},
				ec_redeemed_status: {
					title: ORDER_EC_REDEEMED_TITLE.ORDER[item.ecRedeemedStatus],
					color: ORDER_EC_REDEEMED_COLOR.ORDER[item.ecRedeemedStatus],
				},
			}));
		},
		paymentMethodOptions() {
			const allOption = { name: 'All method', value: null };
			const options = this.paymentMethodGroupList.map((item) => ({
				name: item.name,
				value: item.id,
			}));

			return [
				allOption,
				...options,
			];
		},
		customerGroupOptions() {
			const allOption = { name: 'All group', value: null };
			const options = this.customerGroupList.data.map((item) => ({
				name: item.name,
				value: item.id,
			}));

			return [
				allOption,
				...options,
			];
		},
		orderTypeOptions() {
			return [
				{ name: 'All types', value: null },
				{ name: 'Normal', value: 'normal' },
				{ name: 'Pre-order', value: 'pre_order' },
			];
		},
		ShippingMethodStatusOptions() {
			return [
				{ value: null, name: 'All method' },
				...this.shippingMethodList.map(transformedShippingMethodDropdownOption),
			];
		},
		branchIdOptions() {
			return [
				{ name: 'All branch', value: null },
				...this.warehouseOptions.map(transformedWarehouseDropdownOption),
			];
		},
	},

	async created() {
		this.isShowFilter = this.hasFilter;

		await Promise.all([
			this.fetchData(),
			this.getPaymentMethodGroups(),
			this.getShippingMethodList(),
			this.getCustomerGroupList(),

			// Prevent from fetch data every time when filter change.
			this.warehouseOptions.length === 0 ? this.getWarehouseList({ per_page: 'all' }) : '',
		]);

		const paymentMethodValue = this.queryParams.payment_method_group
			? Number(this.queryParams.payment_method_group)
			: null;

		const ecRedeemedStatus = this.queryParams.ec_redeemed_status !== undefined ? this.queryParams.ec_redeemed_status : null;

		this.paymentMethod = this.getSelectedValue(paymentMethodValue, this.paymentMethodOptions);
		this.dropdown.customerGroup = this.getSelectedValue(this.queryParams.customer_group, this.customerGroupOptions);
		this.dropdown.channel = this.getSelectedValue(this.queryParams.store, ORDER_CHANNEL_OPTIONS);
		this.dropdown.staffBranchId = this.getSelectedValue(this.queryParams.staff_branch_id, this.branchIdOptions);
		this.dropdown.ecRedeemedStatus = this.getSelectedValue(ecRedeemedStatus, ORDER_EC_REDEEMED_OPTIONS);
	},

	methods: {
		...mapActions({
			getOrders: 'orders/getOrders',
			getPaymentMethodGroups: 'payments/getPaymentMethodGroups',
			getShippingMethodList: 'shippings/getShippingMethodList',
			showToast: 'toast/showToast',
			getCustomerGroupList: 'customerGroups/getCustomerGroupList',
			getWarehouseList: 'warehouses/getWarehouseList',
		}),

		fetchData() {
			this.getOrders(this.queryParams);
		},
		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleStatusOrderChange({ value = null }) {
			this.queryParams.status = value;
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleStatusPaymentChange({ value = null }) {
			this.queryParams.payment_status = value;
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleStatusShippingChange({ value = null }) {
			this.queryParams.shipping_status = value;
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleShippingMethodChange({ value = null }) {
			this.queryParams.shipping_method = value;
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handlePaymentMethodChange({ value = null }) {
			this.queryParams.payment_method_group = value;
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleOrderTypeChange({ value = null }) {
			this.queryParams.type = value;
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleCustomerGroupChange({ value = null }) {
			this.queryParams.customer_group = value;
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleChannelChange({ value = null }) {
			this.queryParams.store = value;
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handlePageChange(pageNum = null) {
			this.queryParams.page = pageNum;
			this.updateUrlParams();
		},
		handleFilterDateChange(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.created_from = start;
			this.queryParams.created_to = end;
			this.updateUrlParams();
		},
		handleFilterPaymentExpireDateChange(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.payment_expire_after = start;
			this.queryParams.payment_expire_before = end;
			this.updateUrlParams();
		},
		handleBranchIdChange({ value = null }) {
			this.queryParams.page = null;
			this.queryParams.staff_branch_id = value;
			this.updateUrlParams();
		},
		handleEcRedeemedChange({ value = null }) {
			this.queryParams.page = null;
			this.queryParams.ec_redeemed_status = value;
			this.updateUrlParams();
		},
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		resetFilter() {
			this.queryParams = {
				q: null,
				status: null,
				payment_status: null,
				shipping_status: null,
				ec_redeemed_status: null,
				page: null,
				r: null,
			};
			this.updateUrlParams();
		},
		// get selected value, use with BaseDropDown components
		getSelectedValue(selectedValue = null, options = []) {
			const statusOptionValues = Object.values(options);
			const findStatus = ({ value }) => value === selectedValue;
			return statusOptionValues.find(findStatus) || { name: null, value: null };
		},
		exportOrder(type) {
			this.exportType = type;
			this.$refs['modal-export-order'].open();
		},
		async handleExportOrder() {
			this.isExporting = true;
			try {
				await postOrderReportExport({
					...this.queryParams,
					report_key: this.exportType,
				});
				this.showToast({
					header: this.$t('global.successMessageTitle'),
					content: `Your request has been completed.`,
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					header: this.$t('global.errorMessageExport'),
					content: this.$t('global.errorMessage'),
					type: 'danger',
				});
			} finally {
				this.isExporting = false;
				this.$refs['modal-export-order'].close();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.filter-box {
		background-color: $color-gray-100;
	}

	.badge-order-status {
		padding: rem(7) rem(8);
	}

	::v-deep .table-custom {
		.order {
			// order-cel
			&-cel {
				word-break: unset;

				span {
					margin-right: rem(8);
				}
			}
		}

		.total {
			// total-col, total-cel
			&-col, &-cel {
				text-align: right;
				word-break: unset;
			}
		}

		.shipment {
			// order-shipping-col, order-shipping-cel
			&-col, &-cel a {
				padding-right: 0;
			}
		}
	}

	// Set placeholder of filter by date range
	::v-deep .form-control-date {
		input[type="input"] {
			@include placeholder-black;
		}
	}
</style>
